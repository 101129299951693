// 
// tables.scss
//

//Table centered (Custom)
.table-centered {

    th,
    td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }

    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {

    th,
    td {
        white-space: nowrap;
    }
}

.compact-table {
    .table {
        .form-group {
            margin-bottom: 0.1rem;
        }

        .form-control {
            padding: 0.1rem 0.2rem;
        }
    }

    &.table-sm {
        td {
            padding: 0.1rem;
        }
    }

    .form-group {
        margin-bottom: 0.1rem;
    }

    .form-control {
        padding: 0.1rem 0.2rem;
    }
}

.table-mb-0 {
    .table {
        margin-bottom: 0 !important;
    }
}